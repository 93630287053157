<template>
    <r-e-dialog title="门锁处理" :visible.sync="dialogVisible" top="5vh" width="900px" :show-footer="false">
        <div style="display:flex;justify-content: space-between;align-items: center;margin-bottom: 5px;margin-top:-15px">
            <div>房间号 : {{equipmentData.name}}</div>
            <el-button type="primary" size="small" @click="clickInformation">新建</el-button>
        </div>

      <r-e-table ref="communityTableRef" :columns="tableDoorlockprocessing" :dataRequest="getFamilyList" :query="formSearch" :height="300">
        <template slot="empty">
          <el-empty/>
        </template>
        <el-table-column slot="toolbar" label="操作" >
          <template slot-scope="{ row }">
            <div class="table-tools">
              <span class="table-btn" @click="lookDetail(row)">查看</span>
            </div>
          </template>
        </el-table-column>
      </r-e-table>
       <!-- 2024-10-17 张晓瑜新建门锁处理信息弹窗 -->
    <dialog-door-lock-processing-information ref="dialogDoorLockProcessingInformation" :equipmentData="equipmentData"  @handleSearch="handleSearch"></dialog-door-lock-processing-information>
    <!-- 2024-10-24 张晓瑜新建门锁处理工单弹窗 -->
    <dialog-door-lock-word-order ref="dialogDoorLockWordOrder" :equipmentData="equipmentData"  @handleSearch="handleSearch"></dialog-door-lock-word-order>
    
    </r-e-dialog>
   
  </template>
  
  <script>
  import {tableDoorlockprocessing} from "@/views/property-management/door-management/data";
  import {openLocksLog, tableoperatingRecordapi} from "@/api/door-management"
  
  export default {
    name: "dialog-show-unlock-record",
    data() {
      return {
        dialogVisible: false,
        dataList: [],
        tableDoorlockprocessing,
        type: '',
        uuid: '',
        dateArr: [],
        formSearch: {}
      }
    },
    props: {
      equipmentData: {
        type: Object,
        default: () => ({})
      }
    },
    components: {
        dialogDoorLockProcessingInformation:()=> import('./dialog-door-lock-processing-information'),
        dialogDoorLockWordOrder:()=>import('./dialog-door-lock-word-order')
    },
    methods: {
      openDialog(data) {
        this.uuid = data;
        this.dialogVisible = true;
      },
      getFamilyList(params) {
        let {uuid} = this;
        // params.type = this.type;
        if (this.dateArr && this.dateArr.length !== 0) {
          let [startDate, endDate] = this.dateArr;
          params.startDate = startDate;
          params.endDate = endDate;
        }
        console.log({uuid, ...params})
        return tableoperatingRecordapi({uuid, ...params});
      },
      handleSearch() {
        this.$refs["communityTableRef"].pNumber = 1;
        this.$refs["communityTableRef"].getTableData();
      },
     //新建门锁处理信息
      clickInformation(){
        const {uuid} = this.equipmentData;
        console.log('uuid',uuid);
        this.$refs["dialogDoorLockProcessingInformation"].openDialog();
      },
      //新建门锁处理信息
      lookDetail(row){
        // console.log('row',row);
        const {uuid} = row;
        this.$refs["dialogDoorLockWordOrder"].openDialog(uuid);
        // this.$refs["dialogDoorLockWordOrder"].openDialog();

      }
    },
  }
  </script>
  
  <style scoped>
  
  </style>